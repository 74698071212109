<template>
  <b-container fluid class="component-reports-free-restricted-capital-multi-series-pie-chart">
    <pie-chart
      :chart-data="this.chartData"
      :options="this.chartOptions"
      :chart-id="'reports-free-restricted-capital-multi-series-pie-chart'"
      :width="100"
      :height="400"
      :css-classes="''"
      :plugins="[]"
    >
    </pie-chart>
  </b-container>
</template>

<script>
import PieChart from '@/components/chartjs/DoughnutChart'
import chartOptions from '@/mixins/defaultChartJsOptions'
import chartPalettes from '@/mixins/defaultChartColorPalettes'
import formatNumberToFull from '@/assets/filters/formatNumberToFull'

export default {
  name: 'FreeRestrictedMultiSeriesPieChart',
  components: { PieChart },
  mixins: [chartOptions, chartPalettes],
  props: {
    apiData: { type: Object },
    currencySymbol: { type: String },
    resolution: { type: String },
    isLoaded: { type: Boolean, default: false }
  },
  data () {
    return {
      chartData: {},
      translationPath: 'reports.balance.free_restricted_capital.'
    }
  },
  methods: {
    fillChart () {
      if (!this.isLoaded) {
        return false
      }

      const aFreeRestrictedLabels = []
      const aTmpFreeRestrictedAssets = { free: [], restricted: [], uncategorized: [] }
      const currencySymbol = this.currencySymbol ? this.currencySymbol : 'kr'
      const oDatasetAssets = { data: [], backgroundColor: [] }
      const oDatasetTypes = { data: [], backgroundColor: [] }
      let oPalette = this.getThreeColorPaletteYellowLight()

      /* Modify the Y-Axis */
      this.chartOptions.scales.yAxes[0].display = false
      this.chartOptions.scales.xAxes[0].display = false
      this.chartOptions.legend.display = true

      /* Make half circle */
      this.chartOptions.rotation = -Math.PI
      this.chartOptions.circumference = Math.PI

      /* Tooltip */
      /* Modify the tooltip onmoauseover */
      this.chartOptions.tooltips.callbacks.label = function (tooltipItem, chartData) {
        const fAmount = chartData.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
        let iLabelIndex = tooltipItem.index
        if (tooltipItem.datasetIndex === 1) {
          iLabelIndex += chartData.datasets[0].data.length
        }
        const sTitle = chartData.labels[iLabelIndex]

        return sTitle + ': ' + formatNumberToFull(fAmount) + ' ' + currencySymbol
      }

      /**
       * Build dataset
       */
      // Step 1. Prepare for sorting by merging into one array
      let sTmpColor = null
      for (const sFreeRestrictedType in this.apiData.accounts) {
        sTmpColor = oPalette.shift()
        for (const iAccountId in this.apiData.accounts[sFreeRestrictedType]) {
          if (this.apiData.accounts[sFreeRestrictedType][iAccountId].balances.closing_balance !== 0) {
            aTmpFreeRestrictedAssets[sFreeRestrictedType].push({
              title: this.apiData.accounts[sFreeRestrictedType][iAccountId].label,
              amount: this.apiData.accounts[sFreeRestrictedType][iAccountId].balances.closing_balance,
              color: sTmpColor
            })
          }
        }
      }

      // Step 2. Sort according to amount for better charts
      for (const sFreeRestrictedType in this.apiData.accounts) {
        aTmpFreeRestrictedAssets[sFreeRestrictedType].sort(function cmp (a, b) {
          return b.amount - a.amount
        })
      }

      // Step 3. Add to dataset (outer dataset)
      for (const sFreeRestrictedType in aTmpFreeRestrictedAssets) {
        for (const idx in aTmpFreeRestrictedAssets[sFreeRestrictedType]) {
          aFreeRestrictedLabels.push(aTmpFreeRestrictedAssets[sFreeRestrictedType][idx].title)
          oDatasetAssets.data.push(aTmpFreeRestrictedAssets[sFreeRestrictedType][idx].amount)
          oDatasetAssets.backgroundColor.push(aTmpFreeRestrictedAssets[sFreeRestrictedType][idx].color)
        }
      }

      // Step 4. Add types (inner dataset)
      oPalette = this.getThreeColorPaletteYellow()
      for (const sFreeRestrictedType in this.apiData.accounts) {
        oDatasetTypes.data.push(this.apiData.assets[sFreeRestrictedType].closing_balance)
        oDatasetTypes.backgroundColor.push(oPalette.shift())

        if (sFreeRestrictedType !== 'uncategorized') {
          aFreeRestrictedLabels.push(this.$t(this.translationPath + sFreeRestrictedType))
        } else if (this.apiData.meta.is_uncategorized_non_zero && sFreeRestrictedType === 'uncategorized') {
          aFreeRestrictedLabels.push(this.$t(this.translationPath + sFreeRestrictedType))
        }
      }

      /* Prepare data for chart */
      this.chartData = {
        labels: aFreeRestrictedLabels,
        datasets: [oDatasetAssets, oDatasetTypes]
      }
    }
  },
  watch: {
    apiData () {
      this.fillChart()
    }
  },
  created () {
    this.fillChart()
  }
}
</script>
