<template>
  <b-container fluid class="component-reports-balance-free-restricted-capital-bar-chart">
    <bar-chart
      :chart-data="this.chartData"
      :options="this.chartOptions"
      :chart-id="'reports-balance-free-restricted-capital-bar-chart'"
      :width="100"
      :height="400"
      :css-classes="''"
      :plugins="[]"
    >
    </bar-chart>
  </b-container>
</template>

<script>
import BarChart from '@/components/chartjs/BarChart'
import chartOptions from '@/mixins/defaultChartJsOptions'
import defaultChartColorPalettes from '@/mixins/defaultChartColorPalettes'
import defaultChartPeriodMixin from '@/mixins/defaultChartPeriodMixin'
import formatNumberToFull from '@/assets/filters/formatNumberToFull'

export default {
  name: 'ProductiveAssetsBarChart',
  components: { BarChart },
  mixins: [chartOptions, defaultChartColorPalettes, defaultChartPeriodMixin],
  props: {
    apiData: { type: Object },
    currencySymbol: { type: String },
    resolution: { type: String },
    isLoaded: { type: Boolean, default: false }
  },
  data () {
    return {
      chartData: {},
      translationPath: 'reports.balance.free_restricted_capital.'
    }
  },
  methods: {
    fillChart () {
      if (!this.isLoaded || !this.apiData) {
        return false
      }
      const labels = []
      this.apiData.meta.periods.map(period => {
        labels.push(this.getTimeLabelFromPeriodLabel(period, this.resolution))
      })

      const suffix = ' ' + this.currencySymbol
      this.chartOptions.tooltips.callbacks.label = function (tooltipItem, data) {
        return data.datasets[tooltipItem.datasetIndex].label + ': ' + formatNumberToFull(tooltipItem.yLabel) + suffix
      }

      const datasets = []
      const palette = this.getThreeColorPaletteYellow()
      for (const sFreeRestrictedType in this.apiData.assets) {
        const dataset = {
          backgroundColor: palette.shift(),
          type: 'bar',
          label: this.$t(this.translationPath + sFreeRestrictedType),
          data: []
        }

        for (const period in this.apiData.assets[sFreeRestrictedType].period_balances) {
          dataset.data.push(this.apiData.assets[sFreeRestrictedType].period_balances[period])
        }

        if (sFreeRestrictedType !== 'uncategorized') {
          datasets.push(dataset)
        } else if (this.apiData.meta.is_uncategorized_non_zero && sFreeRestrictedType === 'uncategorized') {
          datasets.push(dataset)
        }
      }

      this.chartData = {
        labels: labels,
        datasets: datasets
      }
    }
  }
}
</script>
