<template>
  <div class="component-free-restricted-total-table">
    <b-container fluid class="px-0 mt-4 settings-tab">
      <b-row no-gutters>
        <b-col>
          <b-card class="main-gradient-content-card borderlight pt-3 mx-xl-auto">
            <template v-slot:header>
              <b-row no-gutters>
                <b-col>
                  <h2>{{ $t(translationPath + 'total_table_title', { start_date: startDate, end_date: endDate }) }}</h2>
                </b-col>
              </b-row>
            </template>
            <div class="col-lg-8 pl-0">
              <div v-html="$t(translationPath + 'description_total_table')"></div>
            </div>

            <b-table
              class="spirecta-simple-table productive-asset-period-table-amounts mb-0"
              show-empty
              hover
              responsive
              striped
              stacked="md"
              :items="tableData"
              :fields="tableColumns"
              :busy="!isLoaded"
              :tbodyTrClass="trClass"
            >
              <template v-slot:table-busy>
                <loader/>
              </template>

              <!-- END CUSTOM SLOTS -->
            </b-table>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Loader from '@/components/common/Loader'
import formatAmount from '@/assets/filters/formatAmount'
import { mapState } from 'vuex'

export default {
  name: 'ProductiveAssetsTotalTable',
  components: { Loader },
  props: {
    isLoaded: { type: Boolean, default: false },
    startDate: { type: String, default: null },
    endDate: { type: String, default: null },
    apiData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      translationPath: 'reports.balance.free_restricted_capital.',
      tableData: [],
      tableColumns: []
    }
  },
  computed: {
    ...mapState('user', ['currentCOA'])
  },
  methods: {
    toggleChart (showChart) {
      this.showChart = showChart
      this.$emit('chart-to-display', { from_tab: 'summary', chart: showChart })
    },
    buildTableDataRows () {
      const data = {
        free: { title: this.$t(this.translationPath + 'free'), opening_balance: 0, period_change: 0, closing_balance: 0, row_type: 'data' },
        restricted: { title: this.$t(this.translationPath + 'restricted'), opening_balance: 0, period_change: 0, closing_balance: 0, row_type: 'data' },
        uncategorized: { title: this.$t(this.translationPath + 'uncategorized'), opening_balance: 0, period_change: 0, closing_balance: 0, row_type: 'data' }
      }
      const sumRow = { title: this.$t('common.sum'), opening_balance: 0, period_change: 0, closing_balance: 0, row_type: 'total' }
      const aTypes = ['free', 'restricted', 'uncategorized']

      if (this.apiData) {
        for (const idx in aTypes) {
          data[aTypes[idx]].opening_balance = this.apiData.assets[aTypes[idx]].period_balances.opening_balance
          data[aTypes[idx]].period_change = this.apiData.assets[aTypes[idx]].period_change
          data[aTypes[idx]].closing_balance = this.apiData.assets[aTypes[idx]].closing_balance
          data[aTypes[idx]].closing_percentage = this.apiData.assets[aTypes[idx]].closing_percentage
        }

        sumRow.opening_balance = this.apiData.totals.period_balances.opening_balance
        sumRow.period_change = this.apiData.totals.period_change
        sumRow.closing_balance = this.apiData.totals.closing_balance
        sumRow.closing_percentage = 100
      }

      const rows = [data.free, data.restricted]
      if (this.apiData && this.apiData.meta.is_uncategorized_non_zero) {
        rows.push(data.uncategorized)
      }
      rows.push(sumRow)

      return rows
    },
    buildTableColumns () {
      const cols = [
        { key: 'title', label: this.$t(this.translationPath + 'th_types') },
        { key: 'opening_balance', label: this.$t('reports.balance.common.opening_balance'), class: 'text-right', formatter: this.amountFormatter },
        { key: 'period_change', label: this.$t('reports.balance.common.period_change'), class: 'text-right', formatter: this.amountFormatter },
        { key: 'closing_balance', label: this.$t('reports.balance.common.closing_balance'), class: 'text-right', formatter: this.amountFormatter },
        { key: 'closing_percentage', label: this.$t('reports.balance.common.closing_balance_percentage'), class: 'text-right', formatter: this.percentageFormatter }
      ]

      return cols
    },
    amountFormatter (value) {
      return this.currentCOA.locale ? formatAmount(value, this.currentCOA.locale, this.currentCOA.currency, this.currentCOA.currency_iso, true, 0) : Math.ceil(value)
    },
    percentageFormatter (value) {
      return parseFloat(value).toFixed(1).toString() + ' %'
    },
    trClass (item) {
      if (!item) {
        return ''
      }
      if (Object.prototype.hasOwnProperty.call(item, 'row_type')) {
        return 'tr-' + item.row_type.replace(/_/, '-')
      }

      return ''
    }
  },
  watch: {
    apiData: {
      handler: function () {
        this.tableColumns = this.buildTableColumns()
        this.tableData = this.buildTableDataRows()
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss">
.component-free-restricted-total-table{
}
</style>
